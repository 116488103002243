body {
	background: #eff7f8 !important;
}

.login-in-page {
	background: rgba(8, 155, 171, 1);
}
.login-in-page .login-in-page-bg {
	position: relative;
	overflow: hidden;
	/* margin: 65px; */
	padding: 50px 123px;
}
.login-in-page .login-in-page-bg::after {
	margin: 27px;
	position: absolute;
	content: "";
	top: 25px;
	bottom: 109px;
	left: 98px;
	right: 299px;
	z-index: -1;
	border-radius: 40px;
	min-height: 487px;
	background: rgba(8, 155, 171, 1);
	background: -moz-linear-gradient(
		left,
		rgba(8, 155, 171, 1) 0%,
		rgba(13, 181, 200, 1) 100%
	);
}
.sign-up-page .sign-up-page-bg::after {
	bottom: -22px;
}
.login-in-page .login-in-detail .top {
	margin-bottom: 45px;
}
.login-in-detail {
	padding: 32px 53px;
	height: 100vh;
	border-radius: 40px;
}
.forgot-pass-form {
	top: 6% !important;
}
.login-in-from,
.sign-in-from {
	padding: 20px 60px;
	border-radius: 37px;
	position: absolute;
	top: 9%;
	min-height: 416px;
	left: 0;
	right: 0;
	background: #fff;
}
.login-in-page a {
	text-decoration: none;
	color: #089bab;
}
.h6-head {
	color: #089bab;
}
.form-group {
	margin-bottom: 1rem;
}
.custom-control-input {
	margin-right: 3px;
}

/* ant design css */

.ant-row {
	display: initial;
}
.ant-form-item .ant-form-item-label {
	text-align: start;
}
.ant-form-item {
	margin-bottom: 20px;
}
.btn-primary {
	padding-bottom: 32px !important;
}
.ant-form-item .ant-form-item-explain {
	font-size: 12px;
}
.button-login {
	text-align: center;
}
.login-in-from .ant-form-item {
	margin-bottom: 10px;
}
@media (max-width: 768px) {
	.login-img-sec,
	.signup-img-sec {
		display: none;
	}
	.login-in-page .login-in-page-bg::after {
		display: none;
	}
}

@media (max-width: 992px) {
	.login-in-from,
	.sign-in-from {
		position: relative !important;
		padding: 16px 33px;
		top: 4%;
	}
	.login-in-detail {
		padding: 35px;
		margin-top: 66px;
	}
	.login-in-detail .top img {
		margin-top: 39%;
	}
}
